import styled from 'styled-components';

export const OffersContainer = styled.div`
  width: 100%;
  padding: 0 0 3rem 0;
`;

export const OffersHeader = styled.div`
  display: grid;
  grid-template-columns: auto 250px auto;
  align-items: center;
`;

export const OffersHeaderTitle = styled.h1`
  color: #fed964;
  text-align: center;
  font-family: Caudex;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
`;

export const HorizontalLine1 = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(254, 217, 100, 0) 0%,
    rgba(254, 217, 100, 0.5) 100%
  );
`;

export const HorizontalLine2 = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(254, 217, 100, 0.5) 0%,
    rgba(254, 217, 100, 0) 100%
  );
`;

export const StoreCardsContainer = styled.div`
  display: grid;
  grid-template-areas:
    'a b c'
    'a d e';
  gap: 46px;

  @media (max-width: 1000px) {
    grid-template-areas:
      'a a c'
      'b d e';
    gap: 42px;
  }
  @media (max-width: 768px) {
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e';
    gap: '36px';
  }
`;

export const CategoryContainer = styled.div`
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const ButtonGroupDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #27204e;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Button = styled.div`
  padding: 0px 16px;
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;

  min-width: 128.29px;
  min-height: 56px;
  color: #fff;
  white-space: nowrap;
  border-left: 1px solid #27204e;
  background: #160e1f;
  cursor: pointer;

  text-align: center;

  @media (max-width: 768px) {
    border-top: 1px solid #27204e;
    padding: 16px 16px;
    white-space: normal;
  }

  &:hover {
    background-color: #27204e;
  }

  &.active {
    background: linear-gradient(180deg, #8d32c0 0%, #9012fd 125%);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CategoryDiv = styled.div`
  width: 100%;
  min-height: 750px;
  display: grid;
  gap: 46px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 84px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 72px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 34px;
    margin-bottom: 50px;
  }
`;
