import React, { useState, useEffect } from "react";
import { HomePageLayout } from "./HomePage.style";
import Header from "../Header/Header";
import Cart from "../Cart/Cart";
import Welcome from "../Welcome/Welcome";
import Offers from "../Offers/Offers";
import Footer from "../Footer/Footer";
import { useEpicCode } from "../../contexts/EpicCodeContext";
import { useAccount, useBalance } from "wagmi";
import toast from "react-hot-toast";

export default function HomePage() {
  const [luxBalance, setLuxBalance] = useState(null);
  const { address } = useAccount();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cart, setCart] = useState([]);
  
  const { data: rstBalance } = useBalance({
    address,
    token: process.env.REACT_APP_RST_TOKEN_CONTRACT_ADDRESS,
    watch: true,
  });

  const code = useEpicCode();

  const addToCart = (item, qty) => {
    const existingItemIndex = cart.findIndex(cartItem => cartItem.ItemId === item?.ItemId);
    if (existingItemIndex >= 0) {
      // Update the quantity of the existing item
      const updatedCart = cart.map((cartItem, index) => {
        if (index === existingItemIndex) {
          return {
            ...cartItem,
            Qty: cartItem.Qty + qty
          };
        }
        return cartItem;
      });
      toast.success("Item added to cart");
      setCart(updatedCart);
    } else {
      const newItem = item;
      newItem.Qty = qty;
      toast.success("Item added to cart");
      setCart([...cart, newItem]);
    }
  };

  const enforceMaxPurchases = (cartItems) => {
    return cartItems.map(item => {
      const maxAllowed = item.MaxSupply > 0 ? Math.min(item.MaxAccPurchases, item.MaxSupply) : item.MaxAccPurchases;
      return {
        ...item,
        Qty: maxAllowed > 0 ? Math.min(item.Qty, maxAllowed) : item.Qty
      };
    });
  };

  useEffect(() => {
    const updatedCart = enforceMaxPurchases(cart);
    if (JSON.stringify(cart) !== JSON.stringify(updatedCart)) {
      setCart(updatedCart);
    }
  }, [cart]);

  return (
    <HomePageLayout>
      <Header
        code={code}
        setShow={setIsCartOpen}
        luxBalance={luxBalance}
        setLuxBalance={setLuxBalance}
        rstBalance={rstBalance}
      />
      <Cart
        show={isCartOpen}
        setShow={setIsCartOpen}
        cart={cart}
        setCart={setCart}
        setLuxBalance={setLuxBalance}
      />
      <Welcome />
      <Offers
        luxBalance={luxBalance}
        setLuxBalance={setLuxBalance}
        rstBalance={rstBalance}
        toast={toast}
        addToCart={addToCart}
      />
      <Footer />
    </HomePageLayout>
  );
}
