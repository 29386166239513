import styled, { keyframes } from "styled-components";

const sizes = {
  large: 1200,
  medium: 768,
};

const media = {
  large: `(min-width: ${sizes.large}px)`,
  notLarge: `(max-width: ${sizes.large - 1}px)`,
  medium: `(min-width: ${sizes.medium}px) and (max-width: ${sizes.large - 1}px)`,
  small: `(max-width: ${sizes.medium - 1}px)`,
};

export const ConnectPageLeftImg = styled.img`
  height: 100%;
  display: none;
  @media ${media.large} {
    display: initial;
  }
`;

export const ConnectPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #0c0416;

  @media ${media.medium} {
    flex-direction: column;
  }
`;

export const ConnectPageLoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 689px;
  height: 100%;
  gap: 40px;
  padding: 0px 80px;

  @media ${media.small} {
    min-width: 100%;
    padding: 20px;
  }
`;

export const ConnectPageHeadlineDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 46px;

  width: 100%;
  text-align: left;
`;

export const LogoImgDiv = styled.img`
  width: 70px;
`;

export const ConnectPageHeadlineTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ConnectPageHeadlineTitle = styled.span`
  color: #fff;
  font-family: Caudex;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1px;
`;

export const ConnectPageHeadlineContent = styled.span`
  color: #f4e7ff;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  letter-spacing: -0.2px;
  max-width: 529px;

  @media ${media.medium} {
    font-size: 16px;
  }

  @media ${media.small} {
    font-size: 14px;
  }
`;

export const ConnectPageAccountsConnectDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media ${media.small} {
    width: 90%;
  }
`;


export const ConnectPageAccountConnectButton = styled.div`
  padding: 16px 20px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  cursor: pointer;

  border-radius: 14px;
  background: #180f22;
  border: 0.7px solid #180f22;
  opacity: 1;

  &:hover {
    border: 0.7px solid #27204e;
  }

  &.active {
    opacity: 1;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.connected {
    opacity: 1;
    background: linear-gradient(
        270deg,
        rgba(46, 213, 115, 0.2) 0%,
        rgba(46, 213, 115, 0) 20.7%
      ),
      #160e1f;
  }
  @media ${media.small} {
    max-width: 90%;
  }
`;

export const ConnectPageAccountConnectButtonImg = styled.img`
  width: 40px;
`;

export const ConnectPageAccountConnectButtonText = styled.span`
  color: #fff;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  @media ${media.small} {
    font-size: 13px;
  }
`;

export const ConnectPageAccountConnectGoButton = styled.img`
  width: 24px;
`;

export const ConnectPageGoIntoButtonDiv = styled.div`
  border-radius: 100px;
  border: 1px solid #27204e;
  padding: 4px;
  width: 100%;
  @media ${media.small} {
    max-width: 80%;
  }
`;

export const ConnectPageGoIntoButton = styled.div`
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: #fefefe;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 63px;
  cursor: pointer;
  background-color: #4e1080;
  opacity: 0.5;

  &:hover {
    color: white;
    opacity: 1;
  }

  &.active {
    background: linear-gradient(180deg, #8d32c0 0%, #9012fd 125%);
    opacity: 1;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ConnectPageGoIntoRightArrow = styled.img`
  width: 18px;
`;

export const spinnerAnimation = keyframes`
from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const SpinnerBody = styled.div`
  height: ${(p) => (p.height ? p.height : "4rem")};
  width: ${(p) => (p.width ? p.width : "4rem")};
  border: ${(p) => (p.borderWidth ? p.borderWidth : "4px")} solid #d1d5db;
  border-top-color: ${(p) => (p.borderColor ? p.borderColor : "#3b82f6")};
  border-radius: 50%;
  animation: ${spinnerAnimation}
    ${(p) => (p.duration ? `${p.duration}ms` : "800ms")} linear infinite;
`;
