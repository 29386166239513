import styled from "styled-components";
import arrowMediumImage from "../../images/icons/carrousel_btn_r.png";
import envelopebg from "../../images/bg/envelope.png";

export const FooterNewsLetterContainer = styled.div`
  margin-bottom: 0;
  position: relative;
  width: 100%;
  padding: 0;
  @media (max-width: 1000px) {
    margin-bottom: 0px;
  }
`;

export const FooterNewsLetterFormWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1000px) {
    padding: 0;
  }
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;
export const FooterNewsLetterFormContainer = styled.form``;
export const FooterNewsLetterFormHeading = styled.div`
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 10px;
  display: flex;

  color: white;
  i {
    width: 24px;
    height: 24px;
    display: inline-block;

    background: url(${envelopebg}) center center transparent;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
  }
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;
export const FooterNewsLetterFormText = styled.div`
  font-weight: normal;
  margin-bottom: 25px;
  font-size: 16px;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;
export const FooterNewsLetterFormInputWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #8d32c0;
  border-radius: 100px;
  padding: 7px;

  display: flex;
  justify-content: center;
`;

export const FooterNewsLetterFormInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #170625;
  border-radius: 100px;
  padding: 4px 4px;
`;

export const FooterNewsLetterFormInput = styled.input`
  width: 100%;
  height: 50px;
  padding-bottom: 5px;
  line-height: 35px;
  color: white;
  font-size: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 800;
  ::placeholder {
    color: #812ed9 !important;
    opacity: 1;
  }
  ::-webkit-input-placeholder {
    color: #812ed9 !important;
    opacity: 1;
  }
  @media (max-width: 1000px) {
    font-size: 18px;
    height: 40px;
    padding-bottom: 0;
  }
`;
export const FooterNewsLetterFormSubmit = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(${arrowMediumImage}) center center transparent;
  -webkit-appearance: button;
  padding: 10px;
  @media (max-width: 1000px) {
    transform: scale(0.75);
    width: 30px;
    height: 30px;
  }
`;
export const FooterNewsLetterFormEmailSentMessage = styled.div`
  width: 100%;
  height: 60px;
  color: #55e77b;
  font-size: 15px;
  padding-top: 15px;
  line-height: 18px;
  padding-bottom: 15px;
  @media (max-width: 1000px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    line-height: 1.35;
  }
`;
export const FooterNewsLetterFormLoadingSpinnerContainer = styled.div`
  position: absolute;
  right: 0;
  top: 35%;
  transform: translateY(-50%) scale(0.7);
  @media (max-width: 1000px) {
    transform: scale(0.5);
  }
`;
export const FooterNewsLetterFormEmailError = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 3px);
  color: #d58706;
  line-height: 1;
  font-size: 18px;
  text-align: right;
  @media (max-width: 1000px) {
    white-space: nowrap;
    font-size: 13px;
  }
`;
