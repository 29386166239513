import React, { useState, useEffect, useRef } from "react";

import {
  Button,
  ButtonGroupDiv,
  CategoryDiv,
  CategoryContainer,
  HorizontalLine1,
  HorizontalLine2,
  OffersContainer,
  OffersHeader,
  OffersHeaderTitle,
  StoreCardsContainer,
} from "./Offers.style";
import { LayoutWrapper } from "../../components/Layout/Layout.style";

import ItemCard from "../../components/ItemCard/ItemCard";
import Modal from "../../components/Modal/Modal";
import WebSocketRPC from "../../components/WebSocketRPC/WebSocketRPC";

export default function Offers({
  luxBalance,
  setLuxBalance,
  rstBalance,
  addToCart,
}) {
  async function getImage(itemId) {
    let imageSrc = null;
    try {
      const imageModule = await import(`../../images/items/${itemId}.png`);
      imageSrc = imageModule.default;
    } catch (error) {
      try {
        const defaultImageModule = await import(
          "../../images/items/default.png"
        );
        imageSrc = defaultImageModule.default;
      } catch (err) {
        console.error("Default image also missing!");
      }
    }
    return imageSrc;
  }

  const [offers, setOffers] = useState([]);
  const [amountSold, setAmountSold] = useState([]);
  const intervalRef = useRef(null);

  const fetchCatalog = () => {
    fetch(
      `${process.env.REACT_APP_SECURE_FUNCTIONS_ENDPOINT}/rtlolwebsite.getrstcatalog`
    )
      .then((response) => response.json())
      .then((data) => {
        setOffers(data.Offers);
      });
  };

  useEffect(() => {
    fetchCatalog();
    intervalRef.current = setInterval(() => {
      fetchSoldData();
    }, 1000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const fetchSoldData = async (itemId) => {
    // Call subgraph and get amount sold for all items
    const data = JSON.stringify({
      query: `
        query MyQuery {
          items {
            id
            amountSold
          }
        }`
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_CHAIN_ID === 13337 ||
          process.env.REACT_APP_CHAIN_ID === "13337" ?
          'https://subgraph.raini.io/subgraphs/name/beam-testnet/rstsales' :
          'https://subgraph.raini.io/subgraphs/name/beam/rstsales', options);

      const json = await response.json();
      if (json.data && json.data.items) {
        console.log(json.data.items);
        // Convert to integers to match RST Catalog data
        const itemsWithIntegers = json.data.items.map(item => ({
          id: parseInt(item.id, 10),
          amountSold: parseInt(item.amountSold, 10)
        }));
        setAmountSold(itemsWithIntegers);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [imageSources, setImageSources] = useState({});

  useEffect(() => {
    async function loadImages() {
      let sources = {};
      for (let offer of offers) {
        sources[offer.ItemId] = await getImage(offer.ItemId);
      }
      setImageSources(sources);
    }
    loadImages();
  }, [offers]);

  const buttonList = [
    {
      text: "LUX",
      value: "lux",
    },
    {
      text: "Battle Pass",
      value: "battle_pass",
    },
    {
      text: "Packs",
      value: "packs",
    },
    {
      text: "Card Backs",
      value: "card_backs",
    },
    {
      text: "Theme Decks",
      value: "theme_decks",
    },
    {
      text: "Bundles",
      value: "bundles",
    },
  ];

  const [activeTab, setActiveTab] = useState("lux");
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  return (
    <OffersContainer>
      <LayoutWrapper>
        <OffersHeader>
          <WebSocketRPC onEventReceived={fetchCatalog} />
          <HorizontalLine1 />
          <OffersHeaderTitle>Featured</OffersHeaderTitle>
          <HorizontalLine2 />
        </OffersHeader>

        <StoreCardsContainer>
          {offers
            .filter((offer) => offer.IsFeatured === true)
            .sort((a, b) => a.FeaturedSortOrder - b.FeaturedSortOrder)
            .map((offer, index) => (
              <ItemCard
                key={`itemCard_${offer.ItemId}`}
                className={`store${index + 1}`}
                item={{
                  bgColor: offer.IsFeatured
                    ? "rgba(221, 68, 192, 0.30)"
                    : "rgba(230, 190, 103, 0.30)",
                  src: imageSources[offer.ItemId],
                  ...offer,
                  amountSold: amountSold.find(item => item.id === offer.ItemId)?.amountSold || 0
                }}
                onclick={() => {
                  setSelectedItem({
                    ...offer,
                    imageSrc: imageSources[offer.ItemId],
                    amountSold: amountSold.find(item => item.id === offer.ItemId)?.amountSold || 0
                  });
                  setModalShow(true);
                }}
              />
            ))}
        </StoreCardsContainer>

        <CategoryContainer>
          <ButtonGroupDiv>
            {buttonList.map((item, index) => {
              const hasItems = offers.some(
                (offer) => offer.ItemType === item.value
              );
              return (
                <Button
                  className={activeTab === item.value ? "active" : ""}
                  key={`group-button${index + 1}`}
                  onClick={() => {
                    if (hasItems) {
                      setActiveTab(item.value);
                    }
                  }}
                  disabled={!hasItems}
                >
                  {item.text}
                </Button>
              );
            })}
          </ButtonGroupDiv>
          <CategoryDiv>
            {offers
              .filter((offer) => offer.ItemType === activeTab)
              .map((offer, index) => (
                <ItemCard
                  key={`itemCard_${offer.ItemId}`}
                  className="card"
                  item={{
                    bgColor: offer.IsFeatured
                      ? "rgba(221, 68, 192, 0.30)"
                      : "rgba(230, 190, 103, 0.30)",
                    src: imageSources[offer.ItemId],
                    ...offer,
                    amountSold: amountSold.find(item => item.id === offer.ItemId)?.amountSold || 0
                  }}
                  onclick={() => {
                    setSelectedItem({
                      ...offer,
                      imageSrc: imageSources[offer.ItemId],
                      amountSold: amountSold.find(item => item.id === offer.ItemId)?.amountSold || 0,
                    });
                    setModalShow(true);
                  }}
                />
              ))}
          </CategoryDiv>
        </CategoryContainer>
      </LayoutWrapper>
      <Modal
        handlePurchase={(item, qty) => addToCart(item, qty)}
        item={selectedItem}
        show={modalShow}
        setShow={setModalShow}
        luxBalance={luxBalance}
        setLuxBalance={setLuxBalance}
        rstBalance={rstBalance}
      />
    </OffersContainer>
  );
}
