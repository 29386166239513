import axios from 'axios';

const API_BASE_URL = 'https://u6m2x5xqa8.execute-api.us-west-1.amazonaws.com/';

export const apiEmailSubscribe = data =>
  axios({
    method: 'post',
    url: `${API_BASE_URL}subscribers`,
    data,
  });

export const apiContact = data =>
  axios({
    method: 'post',
    url: `${API_BASE_URL}contacts`,
    data,
  });
