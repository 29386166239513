import { createGlobalStyle } from 'styled-components';
import fontAvenirLight from './AvenirLTStd-Light.otf';
import fontAvenirBook from './AvenirLTStd-Book.otf';
import fontAvenirMedium from './AvenirLTStd-Medium.otf';
import fontAvenirHeavy from './AvenirLTStd-Heavy.otf';
import fontAvenirBlack from './AvenirLTStd-Black.otf';

export const GlobalFonts = createGlobalStyle`
  @font-face {
      font-family: "Avenir";
      font-display: swap;
      font-weight: 100 400;
      src: url(${fontAvenirLight}) format("opentype");
  }

  @font-face {
      font-family: "Avenir";
      font-display: swap;
      font-weight: 400 500;
      src: url(${fontAvenirBook}) format("opentype");
  }

  @font-face {
      font-family: "Avenir";
      font-display: swap;
      font-weight: 500 700;
      src: url(${fontAvenirMedium}) format("opentype");
  }

  @font-face {
      font-family: "Avenir";
      font-display: swap;
      font-weight: 700 800;
      src: url(${fontAvenirHeavy}) format("opentype");
  }

  @font-face {
      font-family: "Avenir";
      font-weight: 800 1000;
      font-display: swap;
      src: url(${fontAvenirBlack}) format("opentype");
  }
`