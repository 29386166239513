import React, { useState, useEffect, useRef } from 'react';
import contractABI from "../../abi/RstSalesBeam.json";
const { ethers } = require('ethers');

const contractAddress = process.env.REACT_APP_RST_SALES_CONTRACT_ADDRESS

const WebSocketRPC = ({ onEventReceived }) => {
    const [events, setEvents] = useState([]);
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');
    const providerRef = useRef(null); // Ref to store the WebSocket provider

    const connectWebSocket = () => {
        const chainId = process.env.REACT_APP_CHAIN_ID;
        const isTestnet = parseInt(chainId, 10) === 13337;
        const provider = isTestnet
            ? new ethers.providers.WebSocketProvider('wss://rpc-raini.testnet.onbeam.com/ws')
            : new ethers.providers.WebSocketProvider('wss://rpc-raini.mainnet.onbeam.com/ws');

        provider._websocket.onclose = () => {
            setConnectionStatus('Disconnected');
            setTimeout(connectWebSocket, 3000); // Try to reconnect every 3 seconds
        };

        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        contract.on('PurchaseReceipt', (sender, accountId, purchaseId, itemId, amount, lineTotal) => {
            const eventDetails = `Event received: ${sender} ${accountId} ${purchaseId} ${itemId} ${amount} ${lineTotal}`;
            setEvents(prevEvents => [...prevEvents, eventDetails]);
            setTimeout(() => { onEventReceived(); }, 2000); // Trigger a refresh of the catalog
        });

        setConnectionStatus('Connected');
        providerRef.current = provider; // Store the provider in the ref
    };

    useEffect(() => {
        connectWebSocket();

        // Clean up the event listener and WebSocket connection when the component unmounts
        return () => {
            if (providerRef.current) {
                providerRef.current.removeAllListeners('PurchaseReceipt');
                providerRef.current._websocket.close();
            }
            setConnectionStatus('Disconnected');
        };
    }, []); // Empty dependency array ensures this runs once

    return (
        <div style={{ display: "none" }}></div>
    );
};

export default WebSocketRPC;
