import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  position: relative;
  z-index: 10;
  max-width: ${props => (props.width ? `${props.width}px` : '1300px')};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
