import "./App.css";
import { EpicCodeProvider } from "./contexts/EpicCodeContext";
import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./containers/HomePage/HomePage";
import ConnectPage from "./containers/ConnectPage/ConnectPage";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";

import { GlobalFonts } from "./fonts/Fonts";

const beamMainnet = {
  id: 4337,
  name: "Beam Mainnet",
  network: "beam-mainnet",
  nativeCurrency: { name: "BEAM", symbol: "BEAM", decimals: 18 },
  rpcUrls: {
    default: { http: "https://subnets.avax.network/beam/mainnet/rpc" },
  },
  testnet: false,
};

const beamTestnet = {
  id: 13337,
  name: "Beam Testnet",
  network: "beam-testnet",
  nativeCurrency: { name: "BEAM", symbol: "BEAM", decimals: 18 },
  rpcUrls: {
    default: { http: "https://subnets.avax.network/beam/testnet/rpc" },
  },
  testnet: true,
};

const chains =
  process.env.REACT_APP_CHAIN_ID === 13337 ||
    process.env.REACT_APP_CHAIN_ID === "13337"
    ? [beamTestnet]
    : [beamMainnet];

const { provider } = configureChains(chains, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (chain.network === "beam-mainnet") {
        return { http: beamMainnet.rpcUrls.default.http };
      } else if (chain.network === "beam-testnet") {
        return { http: beamTestnet.rpcUrls.default.http };
      } else {
        throw new Error("Unsupported chain network");
      }
    },
  }),
]);

// buffer polyfill: https://github.com/wagmi-dev/wagmi/discussions/163#discussioncomment-2177209
global.Buffer = global.Buffer || require("buffer").Buffer;

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({ chains }),
  ],
  provider,
});

async function switchToBeamNetwork() {
  try {
    const chainId =
      "0x" + parseInt(process.env.REACT_APP_CHAIN_ID, 10).toString(16); // Convert the chainId to hexadecimal
    console.log(chainId);
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  } catch (error) {
    console.error(error);
  }
}

function App() {
  const [epicCode, setEpicCode] = useState(null);

  useEffect(() => {
    // Get the code from the URL query parameters
    const params = new URLSearchParams(window.location.search);
    const queryCode = params.get("code");

    // If code is in the URL, set it in the state and store it in localStorage
    if (queryCode) {
      setEpicCode(queryCode);
      localStorage.setItem("epic_code", queryCode);
    } else {
      // If code is not in the URL, try to get it from localStorage
      const storedEpicCode = localStorage.getItem("epic_code");
      if (storedEpicCode) {
        setEpicCode(storedEpicCode);
      }
    }
  }, []);

  return (
    <EpicCodeProvider value={epicCode}>
      <WagmiConfig client={wagmiClient}>
        <div className="App">
          <Router>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/connect" replace={true} />}
              />
              <Route exact path="/home" Component={HomePage} />
              <Route exact path="/connect" Component={ConnectPage} />
            </Routes>
            <GlobalFonts />
          </Router>
        </div>
      </WagmiConfig>
    </EpicCodeProvider>
  );
}

export default App;
