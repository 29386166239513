import styled, { keyframes } from "styled-components";

const sizes = {
  large: 1200,
  medium: 768,
};

const media = {
  large: `(min-width: ${sizes.large}px)`,
  notLarge: `(max-width: ${sizes.large - 1}px)`,
  medium: `(min-width: ${sizes.medium}px) and (max-width: ${sizes.large - 1}px)`,
  small: `(max-width: ${sizes.medium - 1}px)`,
};

export const ModalDiv = styled.div`
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background: rgba(34, 12, 52, 0.5);
  backdrop-filter: blur(50px);
  z-index: 9999;

  display: none;

  &.modal-show {
    display: flex;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const ModalHeader = styled.div`
  display: flex;
  padding: 28px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 18px 18px 0px 0px;
  border-bottom: 1px solid #27204e;
  background: #160e1f;
`;

export const ModalHeaderTitle = styled.span`
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: white;
`;

export const ModalCloseButton = styled.img`
  width: 32px;
  height: 32px;

  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 28px 28px 36px 28px;
  gap: 36px;

  border-radius: 0px 0px 18px 18px;
  background: #0c0416;
  backdrop-filter: blur(2px);
`;

export const ModalBodyItemDiv = styled.div`
  width: 349px;
  height: 402px;
  @media ${media.small} {
    display: none;
  }
`;

export const ModalBodyDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const ModalBodyDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalBodyDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
`;

export const ModalBodyDescriptionTitle = styled.span`
  color: #fefefe;
  font-family: Avenir;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ModalBodyDescriptionContent = styled.span`
  color: #f4e7ff;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 475px;
`;

export const ModalBodyItemPriceDiv = styled.div`
  display: flex;
  gap: 4px;
`;

export const DiscountPrice = styled.span`
  color: #e5371c;
  text-align: center;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration-line: line-through;
`;

export const ItemPrice = styled.span`
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const ModalCountDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const CountMinusPlusHelper = styled.img`
  width: 48px;
  height: 48px;

  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const CountInput = styled.input`
  padding: 12px;
  max-width: 100px;

  border-radius: 70px;
  border: 1px solid #27204e;
  background: #160e1f;
  text-align: center;

  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PriceChangeDiv = styled.div`
  display: flex;
  gap: 4px;
`;

export const PriceChangeSpan = styled.span`
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const PurchaseButtonDiv = styled.div`
  border-radius: 100px;
  border: 1px solid #27204e;
  padding: 4px;

  cursor: pointer;

  &:hover {
    border-color: #8d32c0;
  }

  @media ${media.small} {
    margin-top: 3rem;
  }
`;

export const PurchaseButton = styled.div`
  padding: 9px;
  border-radius: 63px;
  background: linear-gradient(180deg, #8d32c0 0%, #9012fd 125%);

  color: #fefefe;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.disabled, &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #0000;
  width: 8px;
  height: 8px;
  animation: ${spin} 1s linear infinite;
  margin-right: 8px;
  display: inline-block;
`;
