import React from "react";

import Hero1 from "../../images/bg/hero_1.png";
import Hero2 from "../../images/bg/hero_2.png";
import {
  FlexibleDiv,
  FlexibleImg,
  WelcomeBackground,
  WelcomeContainer,
  WelcomeText,
} from "./Welcome.style";

export default function Welcome() {
  return (
    <WelcomeContainer>
      <WelcomeText>
        Welcome to the{" "}
        <WelcomeText className="rainiverse">Rainiverse Store</WelcomeText>
      </WelcomeText>
      <WelcomeBackground />
      <FlexibleDiv top={50} left={1}>
        <FlexibleImg src={Hero1} width={450} height={420} alt="hero_1" />
      </FlexibleDiv>
      <FlexibleDiv top={40} right={-93}>
        <FlexibleImg src={Hero2} width={455} height={430} alt="hero_2" />
      </FlexibleDiv>
      <FlexibleDiv
        left="0"
        bottom="0"
        style={{
          width: "100%",
          height: "75%",
          background:
            "linear-gradient(180deg, rgba(12, 4, 22, 0.00) 0%, #0C0416 100%)",
        }}
      />
    </WelcomeContainer>
  );
}
