import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";

import {
  CountInput,
  CountMinusPlusHelper,
  DiscountPrice,
  ItemPrice,
  ModalBody,
  ModalBodyDescription,
  ModalBodyDescriptionContent,
  ModalBodyDescriptionTitle,
  ModalBodyDetail,
  ModalBodyDetailDiv,
  ModalBodyItemDiv,
  ModalBodyItemPriceDiv,
  ModalCloseButton,
  ModalContainer,
  ModalCountDiv,
  ModalDiv,
  ModalHeader,
  ModalHeaderTitle,
  PriceChangeDiv,
  PriceChangeSpan,
  PurchaseButton,
  PurchaseButtonDiv,
  Loader,
} from "./Modal.style";

import closeImg from "../../images/icons/close.png";
import ItemCard from "../ItemCard/ItemCard";
import RstIcon from "../../images/icons/RST_128.png";
import MinusImg from "../../images/icons/minus.png";
import PlusImg from "../../images/icons/plus.png";

export default function Modal({
  handlePurchase,
  item,
  show,
  setShow,
  isLoading,
  isPurchasing,
  rstBalance,
}) {

  const [count, setCount] = useState(1);
  const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [userPurchases, setUserPurchases] = useState([]);

  useEffect(() => {
    // Check for insufficient funds
    const calculatedTotalPrice = (item?.UnitPriceRst || 0) * count;
    let parsedRstBalance = parseFloat(
      ethers.utils.formatEther(rstBalance?.value || "0")
    );
    setIsInsufficientFunds(parsedRstBalance < calculatedTotalPrice);

    // Check for sold out condition
    if (item.MaxSupply !== 0 && item.amountSold >= item.MaxSupply) {
      setIsSoldOut(true);
    } else {
      setIsSoldOut(false);
    }
  }, [item?.UnitPriceRst, count, rstBalance, item.amountSold]);

  useEffect(() => {
    if (show && item) {
      fetchUserSoldData();
    }
  }, [show, item]);

  useEffect(() => {
    if (show) {
      setCount(1);
    }
  }, [show]);

  if (!item || !item.Name) {
    return "";
  }

  const fetchUserSoldData = async () => {
    // Call subgraph and aggregate purchases by currently connected wallet by item
    const aggregatePurchases = function (purchases) {
      const itemMap = new Map();

      purchases.forEach(purchase => {
        const itemId = purchase.item.id;
        const amount = parseInt(purchase.amount, 10);

        if (itemMap.has(itemId)) {
          itemMap.set(itemId, itemMap.get(itemId) + amount);
        } else {
          itemMap.set(itemId, amount);
        }
      });

      return Array.from(itemMap).map(([itemId, amount]) => ({ itemId, amount: amount.toString() }));
    }
    let userAccount = localStorage.getItem("epic_account_id");
    console.log(userAccount);
    const data = JSON.stringify({
      query: `
        query MyQuery {
          purchases(where: {user: "0x${userAccount}"}) {
            amount
            item {
              id
            }
          }
        }`
    });
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_CHAIN_ID === 13337 ||
          process.env.REACT_APP_CHAIN_ID === "13337" ?
          'https://subgraph.raini.io/subgraphs/name/beam-testnet/rstsales' :
          'https://subgraph.raini.io/subgraphs/name/beam/rstsales', options);

      const json = await response.json();
      if (json.data && json.data.purchases) {
        const aggregatedData = aggregatePurchases(json.data.purchases);
        console.log(aggregatedData);
        setUserPurchases(aggregatedData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const remainingAllowedPurchases = () => {
    if (!item?.MaxAccPurchases || item.MaxAccPurchases === 0) {
      return Infinity; // No limit on purchases
    }

    const userPurchaseForItem = userPurchases.find(purchase => purchase.itemId === '' + item.ItemId);
    const alreadyPurchasedAmount = userPurchaseForItem ? parseInt(userPurchaseForItem.amount, 10) : 0;

    return item.MaxAccPurchases - alreadyPurchasedAmount;
  };

  return (
    <ModalDiv className={show ? "modal-show" : ""}>
      <ModalContainer>
        <ModalHeader>
          <ModalHeaderTitle>Item Details</ModalHeaderTitle>
          <ModalCloseButton
            src={closeImg}
            alt="close_img"
            onClick={() => {
              setShow(false);
            }}
          />
        </ModalHeader>
        <ModalBody>
          <ModalBodyItemDiv>
            <ItemCard
              style={{ height: "calc(100% - 40px)", padding: "20px" }}
              item={{
                bgColor: item?.bgColor,
                src: item?.imageSrc,
                discountPercent: item?.discountPercent,
                new: item?.new,
                xp: item?.xp
              }}
            />
          </ModalBodyItemDiv>
          <ModalBodyDetailDiv>
            <ModalBodyDetail>
              <ModalBodyDescription>
                <ModalBodyDescriptionTitle>
                  {item.Name}
                </ModalBodyDescriptionTitle>
                <ModalBodyItemPriceDiv>
                  {item?.FullPriceRst &&
                    item?.FullPriceRst !== item?.UnitPriceRst && (
                      <DiscountPrice style={{ textDecoration: "line-through" }}>
                        {item?.FullPriceRst.toLocaleString("en-US")}
                      </DiscountPrice>
                    )}
                  <ItemPrice>
                    {item?.UnitPriceRst.toLocaleString("en-US")}
                  </ItemPrice>
                  <img src={RstIcon} alt="RST" style={{ width: "18px" }} />
                </ModalBodyItemPriceDiv>

                <ModalBodyDescriptionContent>
                  {item.Description}
                  {item.MaxSupply !== 0 && (
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      <br />
                      {item.MaxSupply - (item.amountSold || 0)} <div style={{
                        fontWeight: "normal", color: "#999", display: "inline-block"
                      }}>/ {item.MaxSupply}</div> Available
                      <br />
                    </div>
                  )}
                </ModalBodyDescriptionContent>
              </ModalBodyDescription>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  marginRight: "auto",
                }}
              >
                <ModalCountDiv>
                  <CountMinusPlusHelper
                    src={MinusImg}
                    className={count <= 1 ? "disabled" : ""}
                    alt="minus_img"
                    onClick={() => {
                      if (count > 1) {
                        setCount(prevCount => prevCount - 1);
                      }
                    }}
                  />
                  <CountInput
                    type="number"
                    disabled
                    value={count}
                    min={0}
                    onChange={(e) => {
                      setCount(e.target.value);
                    }}
                  />
                  <CountMinusPlusHelper
                    src={PlusImg}
                    alt="plus_img"
                    onClick={() => {
                      const remainingPurchases = remainingAllowedPurchases();
                      if (count < remainingPurchases && (item.MaxSupply === 0 || (item.MaxSupply - item.amountSold) > count)) {
                        setCount(prevCount => Math.min(prevCount + 1, remainingPurchases));
                      }
                    }}
                  />
                </ModalCountDiv>
                <PriceChangeDiv>
                  {item?.FullPriceRst &&
                    item?.FullPriceRst !== item?.UnitPriceRst && (
                      <PriceChangeSpan
                        style={{ textDecoration: "line-through" }}
                      >
                        {(item?.FullPriceRst * count).toLocaleString("en-US")}
                      </PriceChangeSpan>
                    )}
                  <PriceChangeSpan>
                    {(item?.UnitPriceRst * count).toLocaleString("en-US")}
                  </PriceChangeSpan>
                  <img src={RstIcon} width={18} alt="item_img" />
                </PriceChangeDiv>
              </div>
            </ModalBodyDetail>
            <PurchaseButtonDiv>
              {isLoading || isPurchasing ? (
                <PurchaseButton disabled className="disabled">
                  <Loader />
                  Purchasing...
                </PurchaseButton>
              ) : (
                <PurchaseButton
                  disabled={isInsufficientFunds || isSoldOut || count > remainingAllowedPurchases()}
                  onClick={() => {
                    if (!isInsufficientFunds && !isSoldOut && count <= remainingAllowedPurchases()) {
                      handlePurchase(item, count);
                    }
                  }}
                >
                  {isSoldOut ? "Sold Out" : (isInsufficientFunds ? "Insufficient $RST" : "Add to Cart")}
                </PurchaseButton>
              )}
            </PurchaseButtonDiv>
          </ModalBodyDetailDiv>
        </ModalBody>
      </ModalContainer>
    </ModalDiv>
  );
}
