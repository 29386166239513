import React, { useState } from 'react';
import {
  FooterNewsLetterContainer,
  FooterNewsLetterFormContainer,
  FooterNewsLetterFormEmailError,
  FooterNewsLetterFormEmailSentMessage,
  FooterNewsLetterFormHeading,
  FooterNewsLetterFormInputContainer,
  FooterNewsLetterFormInputWrapper,
  FooterNewsLetterFormInput,
  FooterNewsLetterFormLoadingSpinnerContainer,
  FooterNewsLetterFormSubmit,
  FooterNewsLetterFormText,
  FooterNewsLetterFormWrapper,
} from './FooterNewsLetter.style';
import { newsLetterContent } from '../../content';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { apiEmailSubscribe } from '../../modules/api';

export default function FooterNewsLetter() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) return false;
    setLoading(true);
    apiEmailSubscribe({ email })
      .then(() => {
        setEmail('');
        setEmailSent(true);
      })
      .catch(() => {
        setEmailError(newsLetterContent.formErrorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
    return false;
  };

  return (
    <FooterNewsLetterContainer>
      <FooterNewsLetterFormWrapper>
        <FooterNewsLetterFormContainer onSubmit={handleSubmit}>
          <FooterNewsLetterFormHeading>
            <span>
              Pssst{' '}
              <span style={{ color: '#9012FD' }}> Never Miss a Drop </span>
            </span>
          </FooterNewsLetterFormHeading>
          <FooterNewsLetterFormText>
            <p style={{ color: '#857696', fontSize: '14px' }}>
              {newsLetterContent.formDescription}
            </p>
          </FooterNewsLetterFormText>
          {emailSent ? (
            <FooterNewsLetterFormEmailSentMessage>
              {newsLetterContent.formSuccessMessage}
            </FooterNewsLetterFormEmailSentMessage>
          ) : (
            <FooterNewsLetterFormInputWrapper>
              <FooterNewsLetterFormInputContainer>
                <FooterNewsLetterFormInput
                  placeholder="your@email.com"
                  type="email"
                  value={email || ''}
                  onChange={e => setEmail(e.target.value)}
                />
                {emailError && (
                  <FooterNewsLetterFormEmailError>
                    {emailError}
                  </FooterNewsLetterFormEmailError>
                )}
                {loading ? (
                  <FooterNewsLetterFormLoadingSpinnerContainer>
                    <LoadingSpinner />
                  </FooterNewsLetterFormLoadingSpinnerContainer>
                ) : (
                  <FooterNewsLetterFormSubmit disabled={!email} type="submit" />
                )}
              </FooterNewsLetterFormInputContainer>
            </FooterNewsLetterFormInputWrapper>
          )}
        </FooterNewsLetterFormContainer>
      </FooterNewsLetterFormWrapper>
    </FooterNewsLetterContainer>
  );
}
