import styled from "styled-components";
import welBg from "../../images/bg/welcomeBackground.png";
import rstIcon from "../../images/icons/RST_128.png";

export const ItemCardImg = styled.img`
  width: 250px;
  height: auto;

  @media (max-width: 1000px) {
    width: 200px;
    height: auto;
  }
  @media (max-width: 768px) {
    width: 175px;
    height: auto;
  }
`;

export const ItemCardImgDiv = styled.div`
  position: relative;
  border-radius: 14px;
  border: 4px solid #27204e;
  background: url("${welBg}"), lightgray 50% / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 20px 20px 61px 20px;
  cursor: pointer;

  &.store1 {
    padding: 0;
    grid-area: a;
    ${ItemCardImg} {
      width: 400px;
    }
  }
  &.store2 {
    grid-area: b;
  }
  &.store3 {
    grid-area: c;
  }
  &.store4 {
    grid-area: d;
  }
  &.store5 {
    grid-area: e;
  }

  &.card {
    padding: 31px 31px 63px 31px;
  }

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 142.5px);
    top: calc(50% - 142.5px);
    width: 285px;
    height: 285px;
    border-radius: 361.5px;
    background: ${(props) => props.backgroundcolor};
    filter: blur(42px);
  }

  &.coming-soon {
    cursor: not-allowed;
  }
`;

export const ItemCardImgMask = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemCardTitleDiv = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  z-index: 25;

  display: flex;
  flex-direction: column;
  gap: 5px;

  background: rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(22px);
`;

export const ItemCardTitle = styled.span`
  overflow: hidden;
  color: #f4e7ff;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Caudex;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
`;

export const AlreadyPurchased = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(46, 213, 115, 1);

  &:before {
    content: " ";
    width: 14px;
    height: 14px;
    background: url("${rstIcon}");
    background-size: contain;
  }
`;

export const ItemPriceDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:after {
    content: " ";
    width: 14px;
    height: 14px;
    background: url("${rstIcon}");
    background-size: contain;
  }
`;

export const ItemPriceText = styled.span`
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  &.origin {
    color: #e5371c;
    text-decoration-line: line-through;
  }
  &.newPrice {
    color: #fff;
  }
`;

export const DiscountBadge = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  border-radius: 60px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(180deg, #e5371c 0%, #ab2612 100%);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.48);

  padding: 6px 12px;

  color: #fff;
  font-family: Caudex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const NewBadge = styled.div`
  position: absolute;
  top: 0px;

  padding: 6px 22px;

  border-radius: 0px 0px 18px 18px;
  border: 2px solid #27204e;
  background: linear-gradient(180deg, #8f1fe6 0%, #6f08be 100%);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.48);

  color: #fff;
  text-align: center;
  font-family: Caudex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const XPBadge = styled.img`
  position: absolute;
  margin-top: 20px;
  width: 144px;
  height: 80px;
`;

export const ComingSoon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;        
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 2rem;
  font-family: Caudex;
  z-index: 2;
`;

export const Ribbon = styled.div`
  position: absolute;
  top: 100px;
  left: 0px;
  background: linear-gradient(180deg, #e5371c 0%, #ab2612 100%);
  color: white;
  padding: 5px;
  z-index: 10;
  font-family: Caudex;
  transform: rotate(-45deg); 
  transform-origin: left bottom;
  white-space: nowrap; 
  overflow: hidden; 
  width: 200px;
`;