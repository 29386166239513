import footerDiscord from "./images/footer-discord.png";
import footerTelegram from "./images/footer-telegram.png";
import footerTwitter from "./images/footer-twitter.png";
import footerGithub from "./images/footer-github.png";
import footerInstagram from "./images/footer-instagram.png";
import footerMedium from "./images/footer-medium.png";
import footerCG from "./images/footer-cg.png";

export const FooterContent = {
  iconItems: [
    {
      iconUrl: footerTelegram,
      url: "https://t.me/rainicornchat",
      id: 1,
    },
    {
      iconUrl: footerMedium,
      url: "https://rainicoin.medium.com/",
      id: 2,
    },
    {
      iconUrl: footerTwitter,
      url: "https://twitter.com/raini_studios",
      id: 3,
    },
    {
      iconUrl: footerDiscord,
      url: "https://discord.gg/rainiverse",
      id: 4,
    },
    {
      iconUrl: footerInstagram,
      url: "https://www.instagram.com/rainicoin",
      id: 5,
    },
    {
      iconUrl: footerGithub,
      url: "https://github.com/rainitoken",
      id: 6,
    },
    {
      iconUrl: footerCG,
      url: "https://www.coingecko.com/en/coins/rainicorn",
      id: 7,
    },
  ],
  columns: [
    {
      id: "rainicornshop",
      columnName: "Shop",
      links: [
        {
          text: "Secondary Market",
          url: "https://rtlol.com/market/",
          id: 1,
        }
      ],
    },
    {
      id: "socialLinks",
      columnName: "Stay Tuned!",
      links: [
        {
          text: "Twitter",
          url: "https://twitter.com/raini_studios",
          id: 1,
        },
        {
          text: "Discord",
          url: "https://discord.gg/rainiverse",
          id: 2,
        }
      ],
    },
  ],
  copyright: `${new Date().getFullYear()}© Rainicorn`,
};

export const newsLetterContent = {
  formHeading: "Pssst, never miss a drop!",
  formDescription: "Enter your e-mail to stay in the loop.",
  formErrorMessage: "Error submitting e-mail, please try again.",
  formSuccessMessage: "E-mail submitted, look out for an e-mail from us soon!",
};
