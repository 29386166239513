import React, { useEffect, useState } from "react";

import xpBadge from "../../images/icons/XPBadge.png";
import propTypes from "prop-types";
import {
  AlreadyPurchased,
  DiscountBadge,
  ItemCardImg,
  ItemCardImgDiv,
  ItemCardImgMask,
  ItemCardTitle,
  ItemCardTitleDiv,
  ItemPriceDiv,
  ItemPriceText,
  XPBadge,
  NewBadge,
  ComingSoon,
  Ribbon
} from "./ItemCard.style";

function ItemCard({ className, style, item, onclick }) {
  /* eslint-disable react/prop-types */

  const [countdown, setCountdown] = useState("");

  const updateCountdown = () => {
    if (item?.StartTime) {
      const startTime = new Date(item.StartTime);
      const now = new Date();
      const timeLeft = startTime - now;

      if (timeLeft > 0) {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setCountdown("");
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [item?.StartTime]);

  const isComingSoon = (item?.IsComingSoon && !item?.StartTime) || countdown !== '';

  return (
    <ItemCardImgDiv
      className={`${className} ${isComingSoon ? "coming-soon" : ""}`}
      style={style}
      backgroundColor={item?.bgColor || "#000"}
      onClick={(isComingSoon) ? null : onclick}
    >
      {item?.RibbonText && item.RibbonText !== "" && (
        <Ribbon>{item.RibbonText}</Ribbon>
      )}
      <ItemCardImgMask>
        <ItemCardImg src={item.src} />
        {item?.xp && <XPBadge src={xpBadge} alt="xpBadge" />}
      </ItemCardImgMask>
      {isComingSoon && !item?.StartTime && <ComingSoon>Coming Soon</ComingSoon>}
      {isComingSoon && item?.StartTime && <ComingSoon>{countdown}</ComingSoon>}
      {item.Name && (
        <ItemCardTitleDiv>
          <ItemCardTitle> {item.Name} </ItemCardTitle>
          {item?.purchased && (
            <AlreadyPurchased>Already Purchased</AlreadyPurchased>
          )}
          {item?.UnitPriceRst && item?.FullPriceRst && item.UnitPriceRst !== item.FullPriceRst && (
            <ItemPriceDiv>
              <ItemPriceText className="origin" style={{ textDecoration: "line-through" }}>
                {item.FullPriceRst.toLocaleString()}
              </ItemPriceText>
              <ItemPriceText className="newPrice">
                {item.UnitPriceRst.toLocaleString()}
              </ItemPriceText>
            </ItemPriceDiv>
          )}
          {item?.UnitPriceRst && item?.UnitPriceRst !== -1 && item.UnitPriceRst === item.FullPriceRst && (
            <ItemPriceDiv>
              <ItemPriceText className="newPrice">
                {item.UnitPriceRst.toLocaleString()}
              </ItemPriceText>
            </ItemPriceDiv>
          )}
          {item.MaxSupply !== 0 && !isNaN(item.MaxSupply) && !isNaN(item.amountSold) && !isComingSoon && (
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {item.MaxSupply - (item.amountSold || 0)} <div style={{
                fontWeight: "normal", color: "#999", display: "inline-block"
              }}>/ {item.MaxSupply}</div> Available
              <br />
            </div>
          )}
        </ItemCardTitleDiv>
      )}
      {item?.SalePercent > 0 && (
        <DiscountBadge>{item?.SalePercent}% OFF</DiscountBadge>
      )}
      {item?.new && <NewBadge> NEW </NewBadge>}
    </ItemCardImgDiv>
  );
}

ItemCard.prototype = {
  className: propTypes.string,
  item: propTypes.object,
};

export default ItemCard;
