import React, { useEffect, useState } from "react";
import LeftImg from "../../images/bg/hero_bg.png";
import LogoImg from "../../images/icons/logo.png";
import EpicLogo from "../../images/icons/epic_logo.png";
import MetamaskLogo from "../../images/icons/metamask_logo.png";
import RightArrow from "../../images/icons/arrow-right.png";
import CheckImg from "../../images/icons/check.png";
import {
  ConnectPageContainer,
  ConnectPageHeadlineContent,
  ConnectPageHeadlineDiv,
  ConnectPageHeadlineTitle,
  ConnectPageLeftImg,
  ConnectPageLoginDiv,
  ConnectPageHeadlineTitleDiv,
  LogoImgDiv,
  ConnectPageAccountsConnectDiv,
  ConnectPageAccountConnectButton,
  ConnectPageAccountConnectButtonImg,
  ConnectPageAccountConnectButtonText,
  ConnectPageAccountConnectGoButton,
  ConnectPageGoIntoButtonDiv,
  ConnectPageGoIntoButton,
  ConnectPageGoIntoRightArrow,
  SpinnerBody,
} from "./ConnectPage.style";
import { useAccount, useConnect } from "wagmi";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ConnectPage() {
  const [epicConnected, setEpicConnected] = useState(false);
  const [isEpicAuthLoading, setIsEpicAuthLoading] = useState(false);

  const { isConnected: walletConnected } = useAccount();
  const { connect, connectors, isLoading } = useConnect();

  const [isBeamNetworkActive, setIsBeamNetworkActive] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      setEpicConnected(true);
    }
  }, []);

  const navigate = useNavigate();

  const beamTestnet = {
    id: 13337,
    name: "Beam Testnet",
    network: "beam-testnet",
    nativeCurrency: { name: "BEAM", symbol: "BEAM", decimals: 18 },
    rpcUrls: {
      default: { http: "https://subnets.avax.network/beam/testnet/rpc" },
    },
    testnet: true,
  };

  const beamMainnet = {
    id: 4337,
    name: "Beam Mainnet",
    network: "beam-mainnet",
    nativeCurrency: { name: "BEAM", symbol: "BEAM", decimals: 18 },
    rpcUrls: {
      default: { http: "https://subnets.avax.network/beam/mainnet/rpc" },
    },
    testnet: false,
  };

  function getNetworkConfiguration(chainId) {
    const networks = {
      13337: beamTestnet,
      4337: beamMainnet,
    };

    return networks[chainId];
  }

  const handleEpicAuthClick = async () => {
    if (isBeamNetworkActive && walletConnected) {
      setIsEpicAuthLoading(true); // Set loading state
      window.location.href = `https://www.epicgames.com/id/authorize?client_id=${process.env.REACT_APP_EPIC_GAMES_CLIENT_ID}&response_type=code&scope=basic_profile%20friends_list%20presence&redirect_url=${process.env.REACT_APP_EPIC_GAMES_REDIRECT_URL}`;
    }
  };

  useEffect(() => {
    const checkNetwork = async () => {
      const network = await window.ethereum.request({
        method: "eth_chainId",
      });
      const beamChainId =
        "0x" + parseInt(process.env.REACT_APP_CHAIN_ID, 10).toString(16);
      setIsBeamNetworkActive(network === beamChainId);
    };
    if (window.ethereum) checkNetwork();
  }, []);

  useEffect(() => {
    const handleChainChanged = async () => {
      const network = await window.ethereum.request({ method: "eth_chainId" });
      const beamChainId =
        "0x" + parseInt(process.env.REACT_APP_CHAIN_ID, 10).toString(16);
      setIsBeamNetworkActive(network === beamChainId);
    };

    // Set up listener
    if (window.ethereum) window.ethereum.on("chainChanged", handleChainChanged);

    // Clean up listener on component unmount
    return () => {
      if (window.ethereum)
        window.ethereum.removeListener("chainChanged", handleChainChanged);
    };
  }, []);

  async function addBeamNetwork() {
    const chainId = process.env.REACT_APP_CHAIN_ID;
    const networkConfig = getNetworkConfiguration(chainId);

    if (!networkConfig) {
      console.error(`No configuration found for chainId: ${chainId}`);
      return;
    }

    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x" + parseInt(chainId, 10).toString(16),
            chainName: networkConfig.name,
            nativeCurrency: networkConfig.nativeCurrency,
            rpcUrls: [networkConfig.rpcUrls.default.http],
            blockExplorerUrls: ["https://subnets.avax.network/beam"],
          },
        ],
      });
    } catch (error) {
      console.error("Failed to add Beam network:", error);
    }
  }

  return (
    <ConnectPageContainer>
      <ConnectPageLeftImg src={LeftImg} alt="left_img" />
      <ConnectPageLoginDiv>
        <ConnectPageHeadlineDiv>
          <LogoImgDiv src={LogoImg} alt="logo_img" />
          <ConnectPageHeadlineTitleDiv>
            <ConnectPageHeadlineTitle>
              Welcome Traveler,
            </ConnectPageHeadlineTitle>
            <ConnectPageHeadlineContent>
              Before accessing the $RST Store, please ensure you have added the
              Beam network and have switched to it.
            </ConnectPageHeadlineContent>
          </ConnectPageHeadlineTitleDiv>
        </ConnectPageHeadlineDiv>
        <ConnectPageAccountsConnectDiv>
          <ConnectPageAccountConnectButton
            className={
              /* eslint-disable */
              epicConnected
                ? isBeamNetworkActive
                  ? "connected"
                  : "active"
                : ""
            }
            onClick={addBeamNetwork}
          >
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <ConnectPageAccountConnectButtonImg
                src="https://images.ctfassets.net/gcj8jwzm6086/2ZXZw0POSuXhwoGTiv2fzh/5b9d9e81acb434461da5addb1965f59d/chain-logo.png"
                alt="Beam Logo"
              />
              <ConnectPageAccountConnectButtonText>
                Add / Switch to Beam
              </ConnectPageAccountConnectButtonText>
            </div>
            {isBeamNetworkActive ? (
              <img style={{ width: "24px" }} src={CheckImg} />
            ) : (
              <img style={{ width: "24px" }} src={RightArrow} />
            )}
          </ConnectPageAccountConnectButton>
          <ConnectPageAccountConnectButton
            className={
              /* eslint-disable */
              epicConnected ? (walletConnected ? "connected" : "active") : ""
            }
            onClick={() => {
              for (const connector of connectors) {
                if (connector.ready) {
                  connect({ connector: connector });
                  break;
                }
              }
            }}
          >
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <ConnectPageAccountConnectButtonImg
                src={MetamaskLogo}
                alt="metamask_logo"
              />
              <ConnectPageAccountConnectButtonText>
                Connect your wallet
              </ConnectPageAccountConnectButtonText>
            </div>
            {!walletConnected ? (
              isLoading ? (
                <SpinnerBody width="24px" height="24px" borderWidth="2px" />
              ) : (
                <ConnectPageAccountConnectGoButton
                  src={RightArrow}
                  alt="right_arrow"
                />
              )
            ) : (
              <ConnectPageAccountConnectGoButton
                src={CheckImg}
                alt="right_arrow"
              />
            )}
          </ConnectPageAccountConnectButton>
          <ConnectPageAccountConnectButton
            className={epicConnected ? "connected" : "active"}
            onClick={handleEpicAuthClick}
            disabled={
              !isBeamNetworkActive || !walletConnected || isEpicAuthLoading
            }
          >
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <ConnectPageAccountConnectButtonImg
                src={EpicLogo}
                alt="epic_logo"
              />
              <ConnectPageAccountConnectButtonText>
                {isEpicAuthLoading
                  ? "Redirecting to Epic Games..."
                  : "Link your Epic Games Store account"}
              </ConnectPageAccountConnectButtonText>
            </div>
            {isEpicAuthLoading ? (
              <SpinnerBody width="24px" height="24px" borderWidth="2px" />
            ) : epicConnected ? (
              <ConnectPageAccountConnectGoButton
                src={CheckImg}
                alt="right_arrow"
              />
            ) : (
              <ConnectPageAccountConnectGoButton
                src={RightArrow}
                alt="right_arrow"
              />
            )}
          </ConnectPageAccountConnectButton>
        </ConnectPageAccountsConnectDiv>
        <ConnectPageGoIntoButtonDiv>
          <ConnectPageGoIntoButton
            className={epicConnected && walletConnected ? "active" : ""}
            onClick={() => {
              if (epicConnected && walletConnected) {
                navigate("/home");
              }
            }}
            disabled={!(epicConnected && walletConnected)}
          >
            <span> Rainiverse Store </span>
            <ConnectPageGoIntoRightArrow src={RightArrow} alt="right_arrow2" />
          </ConnectPageGoIntoButton>
        </ConnectPageGoIntoButtonDiv>
      </ConnectPageLoginDiv>
    </ConnectPageContainer>
  );
}
