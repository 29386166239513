import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 80px;

  .loadingSpinner_duo {
    height: 20px;
    width: 50px;
    background: hsla(0, 0%, 0%, 0);
    position: absolute;
  }

  .loadingSpinner_duo,
  .loadingSpinner_dot {
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .loadingSpinner_duo1 {
    left: 0;
  }

  .loadingSpinner_duo2 {
    left: 30px;
  }

  .loadingSpinner_dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: rgb(70, 87, 223);
    position: absolute;
  }

  .loadingSpinner_dot-a {
    left: 0px;
  }

  .loadingSpinner_dot-b {
    right: 0px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes onOff {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .loadingSpinner_duo1 {
    animation-name: spin;
  }

  .loadingSpinner_duo2 {
    animation-name: spin;
    animation-direction: reverse;
  }

  .loadingSpinner_duo2 .loadingSpinner_dot-b {
    animation-name: onOff;
  }

  .loadingSpinner_duo1 .loadingSpinner_dot-a {
    opacity: 0;
    animation-name: onOff;
    animation-direction: reverse;
  }
`;

function LoadingSpinner() {
  return (
    <Container className="loader">
      <div className="loadingSpinner_duo loadingSpinner_duo1">
        <div className="loadingSpinner_dot loadingSpinner_dot-a" />
        <div className="loadingSpinner_dot loadingSpinner_dot-b" />
      </div>
      <div className="loadingSpinner_duo loadingSpinner_duo2">
        <div className="loadingSpinner_dot loadingSpinner_dot-a" />
        <div className="loadingSpinner_dot loadingSpinner_dot-b" />
      </div>
    </Container>
  );
}

export default LoadingSpinner;
