import styled from "styled-components";

import welcomeBg from "../../images/bg/welcomeBackground.png";

export const CartContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 18px;
  background-color: #0c0416;
  border-left: 1px solid #27204e;
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.3s ease-in-out;
`;

export const CartHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 28px;
  justify-content: space-between;
  align-items: center;
  min-width: 400px;
  background-color: #160e1f;
  border-bottom: 1px solid #27204e;
`;

export const CartHeaderTitle = styled.div`
  display: flex;
  gap: 12px;
  color: white;
  align-items: center;
`;

export const CartHeaderTitleText = styled.span`
  font-family: Avenir;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
`;

export const CartHeaderTitleBadge = styled.div`
  background: #901aef;
  text-align: center;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
`;

export const CartHeaderCloseButton = styled.img`
  width: 32px;
  height: 32px;

  cursor: pointer;
`;

export const CartBody = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 336px);
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 18px;
`;

export const CartItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  color: white;
`;

export const CartItemIndex = styled.span`
  font-family: Avenir;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: center;
`;

export const CartItemContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
`;

export const CartItemContentImg = styled.img`
  border: 3px solid #27204e;
  border-radius: 10px;
  width: 48px;
  height: 48px;

  background: url("${welcomeBg}"), lightgray 50% / cover no-repeat;
`;

export const CartItemContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CartItemContentTextTitle = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CartItemContentTextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const CartItemContentTextPrice = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
`;

export const CartItemContentTextPriceBadge = styled.img`
  width: 18px;
  height: 18px;
`;

export const CartItemDivider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #27204e;
`;

export const CartFooter = styled.div`
  padding: 28px 28px 48px 28px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #160e1f;
  border-top: 1px solid #27204e;
`;

export const CartFooterSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TotalAmountSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
`;

export const TotalAmountText = styled.span`
  font-family: Avenir;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

export const TotalAmountValueSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const TotalAmountValueText = styled.span`
  font-family: Avenir;
  font-size: 20px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
`;

export const TotalAmountValueImg = styled.img`
  width: 17.78px;
  height: 20px;
`;

export const CompletePurchaseButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fefefe;
`;

export const CompletePurchaseButtonArea = styled.div`
  width: 80%;
  border: 1px solid #27204e;
  padding: 4px;
  border-radius: 100px;
  cursor: pointer;
`;

export const CompletePurchaseButton = styled.div`
  background: linear-gradient(180deg, #8d32c0 0%, #9012fd 125%);
  border-radius: 100px;
  padding: 8.5px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LoadingScreen = styled.div`
  background: #0c0416cc;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;

export const LoadingScreenText = styled.span`
  font-family: Avenir;
  font-size: 36px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  padding: 0px 30px;
`;
