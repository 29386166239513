import styled from "styled-components";
import CloudeImg from "../../images/cloud.png";

const sizes = {
  large: 1200,
  medium: 768,
};

const media = {
  large: `(min-width: ${sizes.large}px)`,
  notLarge: `(max-width: ${sizes.large - 1}px)`,
  medium: `(min-width: ${sizes.medium}px) and (max-width: ${sizes.large - 1}px)`,
  small: `(max-width: ${sizes.medium - 1}px)`,
};

export const FooterContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background: ${({ background }) => background || "#0E0922"};
  display: flex;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  position: relative;
  padding-bottom: 30px;
  text-align: center;
  background-color: #17052d;
  width: 100%;
`;

export const FooterContainerTop = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  max-width: 1286px;
  min-width: 320px;
  padding-left: 120px;
  padding-right: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: space-between;
  padding-top: 44px;
  padding-bottom: 44px;

  @media ${media.notLarge} {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterContainerTopCol = styled.div`
  text-align: left;
`;

export const FooterNewsletterContainer = styled(FooterContainerTopCol)`
  display: block;
  @media ${media.notLarge} {
    display: block;
  }
`;

export const FooterContainerHeadline = styled.div`
  @media ${media.notLarge} {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterContainerLogoHeadline = styled.img`
  width: 80px;
`;

export const FooterContainerTopLinksHeadline = styled.div`
  font-weight: 400;
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 40px;
  text-align: left;

  @media ${media.notLarge} {
    margin-bottom: 20px;
  }
`;

export const FooterContainerTopLinksWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FooterTopLinksItem = styled.a`
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  &:hover {
    text-decoration: none;
    color: #ffffff;
    border-bottom: 3px solid #812ed9;
  }
`;

export const FooterLinkColumnsContainer = styled.div`
  display: flex;
  margin: 0px auto 0 100px;
  gap: 100px;

  > * {
    margin: 0 25px; // Spacing for link columns
  }
  @media ${media.medium} {
    & > * {
      margin: 0 10px; // Spacing for link columns
    }
  }

  @media ${media.small} {
    display: none;
  }
`;

export const FooterBottomRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #17052d;

  @media ${media.medium} {
    flex-direction: row;
  }
`;

export const FooterBottomText = styled.div`
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;

  @media ${media.medium} {
    margin-bottom: 0;
  }
`;

export const FooterBottomLink = styled.a`
  font-size: 14px;
  margin-left: 20px;
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterBottomLinksContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;

  @media ${media.medium} {
    margin-bottom: 0;
  }
`;

export const FooterSocialsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const FooterSocialLink = styled.a`
  display: inline-block;
`;

export const SocialIcon = styled.img`
  width: 36px;
  height: 36px;
`;

export const FooterBackgroundImg = styled.img`
  position: absolute;
  left: -8rem;
  bottom: 0;
  max-width: 500px;
  pointer-events: none;
  @media ${media.medium} {
    opacity: 0.5;
  }
  @media ${media.small} {
    opacity: 0.3;
  }
`;
