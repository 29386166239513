import React, { useState, useEffect, props } from "react";
import { useNavigate } from "react-router-dom";

import { LayoutWrapper } from "../../components/Layout/Layout.style";

import logoImage from "../../images/icons/logo.png";
import rtlolImage from "../../images/icons/rtlol.png";
import avatarEth from "../../images/icons/avatar_eth.png";
import rstIcon from "../../images/icons/RST_64.png";
import luxIcon from "../../images/icons/cube.png";
import logOutIcon from "../../images/icons/log_out.png";
import cartIcon from "../../images/icons/cart.png";

import toast, { Toaster } from "react-hot-toast";

import { useAccount, useBalance, useSignMessage, useDisconnect } from "wagmi";

import {
  AvatarEth,
  CartDiv,
  CartImg,
  HeaderBackground,
  HeaderContainer,
  InfoDiv,
  LogoDiv,
  LogoutImg,
  TokenBalance,
  TokenIcon,
  VerticalLine,
  PlayerDetail,
  WalletInfo,
  WalletInfoDiv,
  EpicDisplayName,
  WalletTokenHoldCounts,
} from "./Header.style";

import Big from "big.js";

function weiToLocaleString(wei) {
  return Big(wei)
    .div(10 ** 18)
    .toNumber()
    .toLocaleString(undefined, { maximumFractionDigits: 2 });
}

export default function Header({
  code,
  luxBalance,
  setLuxBalance,
  rstBalance,
  setShow,
}) {
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const [epicDisplayName, setEpicDisplayName] = useState("Loading...");
  const [lastToast, setLastToast] = useState(null);

  const getTokenFromLocalStorage = () => {
    return {
      access_token: localStorage.getItem("epic_access_token"),
      account_id: localStorage.getItem("epic_account_id"),
    };
  };

  const walletExistsInData = (walletAddress, data) => {
    return (
      data &&
      data.WalletAddresses &&
      data.WalletAddresses.includes(walletAddress)
    );
  };

  const signMessage = async (messageToSign) => {
    console.log("I am now going to sign the message");
    console.log(messageToSign);
    const signedMessage = await signMessageAsync({
      message: messageToSign,
      from: address,
    });

    return signedMessage;
  };

  const addWalletAddress = async (account, profileData) => {
    if (!walletExistsInData(account, profileData)) {
      const signedMessage = await signMessage(profileData.MessageToSign);
      const { account_id, access_token } = getTokenFromLocalStorage();

      console.log(
        "I am now going to send the signed message that includes the nonce"
      );
      console.log(profileData);

      console.log(
        JSON.stringify({
          id: account_id,
          token: access_token,
          WalletAddress: account,
          SignedMessage: signedMessage,
        })
      );

      await fetch(
        `${process.env.REACT_APP_PROFILE_ENDPOINT}/rtlolwebsite.addwalletaddress`,
        {
          method: "POST",
          body: JSON.stringify({
            id: account_id,
            token: access_token,
            WalletAddress: account,
            SignedMessage: signedMessage,
          }),
        }
      );
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("epic_access_token");
    localStorage.removeItem("epic_account_id");
    localStorage.removeItem("epic_expires_at");
    localStorage.removeItem("epic_code");
    disconnect();
    window.location.href = process.env.REACT_APP_EPIC_GAMES_REDIRECT_URL;
  };

  useEffect(() => {
    if (rstBalance && Big(rstBalance.value).eq(0)) {
      const now = new Date();
      if (!lastToast || now - lastToast > 60000) {
        toast.custom(
          <div
            style={{
              padding: "16px",
              color: "rgb(255, 255, 255)",
              background:
                "linear-gradient(rgb(141, 50, 192) 0%, rgb(144, 18, 253) 80%)",
              animation:
                "0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 0s 1 normal forwards running go3223188581",
              borderRadius: "10px",
            }}
          >
            <div role="status" aria-live="polite">
              Your RST balance is 0, please follow the{" "}
              <a
                href="https://www.raini.io/purchase-guide"
                style={{ color: "#fff" }}
              >
                guide to purchase $RST
              </a>{" "}
              first.
            </div>
          </div>,
          { duration: 30000 }
        );
        setLastToast(now);
      }
    }
  }, [rstBalance, toast, lastToast]);

  useEffect(() => {
    if (
      (!code || code.length !== 32) &&
      (!localStorage.getItem("epic_access_token") ||
        !localStorage.getItem("epic_account_id"))
    ) {
      // a reliable enough "non-logged-in" state
      // navigate("/connect");
    }

    if (!code || code.length !== 32) {
      return;
    }

    const fetchProfile = async (account_id, access_token) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PROFILE_ENDPOINT}/rtlolwebsite.updateandgetprofile`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Id: account_id,
              Token: access_token,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data && data.Lux !== undefined) {
            setLuxBalance(data.Lux);
          }
          // Users for now should link wallets at: https://rtlol.com/player-profile/
          // if (data && data.MessageToSign) {
          //   addWalletAddress(address, data);
          // }
        } else {
          console.log("Error in response!");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    const epic_token_expires_at = localStorage.getItem("epic_expires_at");

    if (epic_token_expires_at && new Date(epic_token_expires_at) > new Date()) {
      // Token is still valid
      const profile = JSON.parse(
        atob(localStorage.getItem("epic_access_token").split(".")[1])
      );
      setEpicDisplayName(profile.dn);
      fetchProfile(
        localStorage.getItem("epic_account_id"),
        localStorage.getItem("epic_access_token")
      );
      return;
    }

    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_EPIC_AUTH_ENDPOINT}/auth/token?code=${code}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (
        result &&
        !result.error &&
        result.access_token &&
        result.access_token.length > 10
      ) {
        localStorage.setItem("epic_access_token", result.access_token);
        localStorage.setItem("epic_account_id", result.account_id);
        localStorage.setItem("epic_expires_at", result.expires_at);
        const profile = JSON.parse(atob(result.access_token.split(".")[1]));
        setEpicDisplayName(profile.dn);
        fetchProfile(result.account_id, result.access_token);
      } else {
        // console.log('Something went wrong');
      }
    })();
  }, [code]);

  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();

  let formattedRstBalance = rstBalance?.value
    ? weiToLocaleString(rstBalance.value)
    : "Loading...";

  // Error message is required here because useBalance will otherwise silently fail by showing BEAM balance instead
  if (!process.env.REACT_APP_RST_TOKEN_CONTRACT_ADDRESS) {
    formattedRstBalance = "ERROR: No RST Contract Address";
  }

  return (
    <HeaderBackground>
      <div>
        <Toaster
          position="top-center"
          toastOptions={{
            success: {
              style: {
                background: "#0c0416"
              },
            },
            style: {
              padding: "16px",
              color: "#fff",
              background: "linear-gradient(180deg, #8d32c0 0%, #9012fd 80%)",
            },
          }}
        />
      </div>
      <LayoutWrapper>
        <HeaderContainer>
          <div class="logo-container" style={{ 'display': 'flex', 'justifyContent': 'flex-start', 'alignItems': 'center' }}>
            <a href="https://raini.io" target="_blank" rel="noreferrer">
              <LogoDiv src={logoImage} alt="logo" />
            </a>
            <a href="https://rtlol.com" target="_blank" rel="noreferrer">
              <img src={rtlolImage} alt="logo" style={{ height: '34px', 'margin': '0 0 5px 25px' }} />
            </a>
          </div>
          <InfoDiv>
            <WalletInfoDiv>
              <WalletInfo>
                {/* <AvatarEth src={avatarEth} alt="avatar_eth" /> */}
                <PlayerDetail>
                  <EpicDisplayName>{epicDisplayName}</EpicDisplayName>
                  <WalletTokenHoldCounts>
                    <TokenIcon src={rstIcon} />
                    <TokenBalance>{formattedRstBalance}</TokenBalance>
                    <VerticalLine height={12} />
                    <TokenIcon src={luxIcon} />
                    <TokenBalance>
                      {luxBalance !== null
                        ? luxBalance.toLocaleString("en-US")
                        : "Loading..."}
                    </TokenBalance>
                  </WalletTokenHoldCounts>
                </PlayerDetail>
              </WalletInfo>
              <VerticalLine height={43} />
              <div style={{ cursor: "pointer" }} onClick={handleLogout}>
                <LogoutImg src={logOutIcon} alt="logout" />
              </div>
            </WalletInfoDiv>
            {
              <CartDiv onClick={setShow}>
                <CartImg src={cartIcon} alt="cart_icon" />
              </CartDiv>
            }
          </InfoDiv>
        </HeaderContainer>
      </LayoutWrapper>
    </HeaderBackground >
  );
}
