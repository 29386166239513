import React, { useEffect, useState } from "react";

import RstLogo from "../../images/icons/RST_128.png";
import CloseImg from "../../images/icons/close.png";
import FeeImg from "../../images/icons/unicorn.png";
import { ethers } from "ethers";

import {
  CartBody,
  CartContainer,
  CartFooter,
  CartFooterSummary,
  CartHeader,
  CartHeaderCloseButton,
  CartHeaderTitle,
  CartHeaderTitleBadge,
  CartHeaderTitleText,
  CartItem,
  CartItemContent,
  CartItemContentImg,
  CartItemContentText,
  CartItemContentTextContent,
  CartItemContentTextPrice,
  CartItemContentTextPriceBadge,
  CartItemContentTextTitle,
  CartItemDivider,
  CartItemIndex,
  CompletePurchaseButton,
  CompletePurchaseButtonArea,
  CompletePurchaseButtonSection,
  LoadingScreen,
  LoadingScreenText,
  TotalAmountSection,
  TotalAmountText,
  TotalAmountValueImg,
  TotalAmountValueSection,
  TotalAmountValueText,
} from "./Cart.style";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import RstSalesBeamABI from "../../abi/RstSalesBeam.json";
import RstTokenBeamABI from "../../abi/RstTokenBeam.json";

import toast from "react-hot-toast";

export default function Cart(props) {
  const { show, setShow, cart, setCart, setLuxBalance } = props;
  const { address } = useAccount();
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [dynamicArgs, setDynamicArgs] = useState([]);
  const [dynamicArgsApprove, setDynamicArgsApprove] = useState([]);

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    waitForConfirmation: true,
    address: process.env.REACT_APP_RST_SALES_CONTRACT_ADDRESS,
    abi: RstSalesBeamABI,
    functionName: "purchase",
    args: dynamicArgs,
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID, 10),
    gas: 1_000_000n,
    onTransactionHash: (hash) => {
      console.log("Transaction hash:", hash);
    },
    onReceipt: (receipt) => {
      console.log("Receipt:", receipt);
    },
    onError: (error) => {
      // console.error('Error:', error);
    },
  });

  const {
    // approve the RST Sales contract to spend the RST token on the user's behalf
    config: approveConfig,
    error: approveError,
    isError: isApproveError,
  } = usePrepareContractWrite({
    waitForConfirmation: true,
    address: process.env.REACT_APP_RST_TOKEN_CONTRACT_ADDRESS,
    abi: RstTokenBeamABI,
    functionName: "approve",
    args: [
      process.env.REACT_APP_RST_SALES_CONTRACT_ADDRESS,
      ethers.constants.MaxUint256,
    ], // approve for max
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID, 10),
    onTransactionHash: (hash) => {
      console.log("Approval Transaction hash:", hash);
    },
    onReceipt: (receipt) => {
      console.log("Approval Receipt:", receipt);
    },
    onError: (error) => {
      setIsPurchasing(false);
      // console.error('Approval Error:', error);
    },
  });

  const {
    data: approveData,
    error: approveContractError,
    onTransactionHash: onApproveTransactionHash,
    isSuccess: isApproveSuccess,
    isError: isApproveContractError,
    writeAsync: approveWriteAsync,
  } = useContractWrite(approveConfig);
  const { data, error, isError, write } = useContractWrite(config);

  const removeItemFromCart = itemId => {
    const updatedCart = cart.filter(item => item.id !== itemId);
    setCart(updatedCart);
  };

  useEffect(() => {
    if (isApproveSuccess) {
      console.log("Approval successful. Proceeding to purchase...");
    }
  }, [isApproveSuccess]);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID, 10),
    onReceipt(receipt) {
      console.log("Transaction receipt:", receipt);
    },
    onSuccess(data) {
      console.log("Transaction successful:", data);
    },
  });

  useEffect(() => {
    if (isError || isSuccess) {
      setIsPurchasing(false);
      console.log("isError:", isError);
      console.log("isSuccess:", isSuccess);
    }
    if (isSuccess) {
      // setModalShow(false);
      toast("Purchase complete!");
      setCart([]);
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      const callEndpoint = async (delay) => {
        setTimeout(async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_PROFILE_ENDPOINT}/rtlolwebsite.updateandgetprofile`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  Id: localStorage.getItem("epic_account_id"),
                  Token: localStorage.getItem("epic_access_token"),
                }),
              }
            );
            const responseData = await response.json();
            console.log("Endpoint response:", responseData);
            if (responseData && responseData.Lux !== undefined) {
              setLuxBalance(responseData.Lux);
            }
          } catch (err) {
            console.error("Error calling the endpoint:", err);
          }
        }, delay);
      };

      callEndpoint(0);
      callEndpoint(1000);
      callEndpoint(2000);
      callEndpoint(3000);
      callEndpoint(4000);
      callEndpoint(5000);
      callEndpoint(10000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (dynamicArgs.length > 0) {
      // a workaround to make sure the dynamicArgs state is updated before calling write()
      // without this, the first user click on the Purchase button will fail (but subsequent clicks will succeed)
      console.log("dynamicArgs after state update:", dynamicArgs);
      write?.();
    }
  }, [dynamicArgs, write]);

  useEffect(() => {
    const waitForTxConfirmation = async () => {
      if (approvalTxHash) {
        try {
          console.log("Waiting for transaction confirmation...");
          console.log("approvalTxHash", approvalTxHash);
          console.log("Transaction confirmed!");
          setTimeout(() => {
            setDynamicArgs(dynamicArgsApprove);
          }, 3000);
        } catch (error) {
          console.error("Error waiting for transaction:", error);
        }
      }
    };
    waitForTxConfirmation();
  }, [approvalTxHash]);

  const makePurchaseRequest = async (offerRequests) => {
    console.log("makePurchaseRequest called with:", offerRequests);

    // this part is mapped to the user interaction with the modal
    const account_id = localStorage.getItem("epic_account_id");
    const access_token = localStorage.getItem("epic_access_token");

    if (!account_id || !access_token) {
      toast.error("Epic credentials error. Please log out and log in again.");
      return;
    }

    const requestData = {
      Id: account_id,
      Token: access_token,
      WalletAddress: address,
      OfferRequests: offerRequests,
    };

    console.log(requestData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SECURE_FUNCTIONS_ENDPOINT}/rtlolwebsite.SignRstOffers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error making purchase request:", error);
    }
  };

  const { data: allowanceData, error: allowanceError } = useContractRead({
    // check if RST Sales contract is approved to spend the user's RST tokens
    address: process.env.REACT_APP_RST_TOKEN_CONTRACT_ADDRESS,
    abi: RstTokenBeamABI,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_RST_SALES_CONTRACT_ADDRESS],
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID, 10),
    watch: true,
  });

  async function handlePurchase(
    cart,
    address,
    setDynamicArgs,
    setDynamicArgsApprove,
    approveWriteAsync
  ) {
    console.log("handlePurchase called with:", cart);
    const offerRequests = cart.map((item) => ({
      OfferId: item.ItemId,
      Amount: item.Qty,
    }));
    console.log("handlePurchase processing with:", offerRequests);
    setIsPurchasing(true);

    const response = await makePurchaseRequest(offerRequests);
    console.log("makePurchaseRequest response:", response);

    if (response?.[0] && response[0]?.Signature) {
      const { Signature, AbiValues } = response[0];
      const accountId = AbiValues[1];
      const remainingAbiValues = AbiValues.slice(2).map((value) => [value]);
      const args = [accountId, ...remainingAbiValues, [Signature]];
      console.log("Constructed args:", args);
      const MAX_ALLOWANCE = ethers.constants.MaxUint256;
      if (ethers.BigNumber.from(allowanceData).lt(MAX_ALLOWANCE)) {
        console.log("Not enough allowance. Trying to approve...");
        toast("Approval is needed to spend $RST");
        setDynamicArgsApprove(args);
        try {
          const txResult = await approveWriteAsync();
          if (txResult && txResult.hash) {
            setApprovalTxHash(txResult.hash);
          } else {
            console.error("Transaction result is missing the hash property.");
          }
        } catch (error) {
          console.error("Error in writeAsync function:", error);
          setIsPurchasing(false);
        }
      } else {
        console.log("Enough allowance. Proceeding to write...");
        setDynamicArgs(args);
        console.log(
          "dynamicArgsApprove before write (after approval):",
          dynamicArgs
        );
      }
    } else {
      console.log("Signature not found in response. Transaction halted.");
      toast.error("Signature not found in response. Transaction halted.");
    }
  }

  return (
    <CartContainer isOpen={show}>
      {isPurchasing && (
        <LoadingScreen>
          <LoadingScreenText>Please wait</LoadingScreenText>
        </LoadingScreen>
      )}
      <CartHeader>
        <CartHeaderTitle>
          <CartHeaderTitleText>Your Cart</CartHeaderTitleText>
          <CartHeaderTitleBadge>{cart?.length || 0}</CartHeaderTitleBadge>
        </CartHeaderTitle>
        <CartHeaderCloseButton
          onClick={() => {
            setShow(false);
          }}
          src={CloseImg}
          alt="close_btn"
        />
      </CartHeader>
      <CartBody>
        {cart?.map((item, _index) => (
          <>
            <CartItem>
              <CartItemIndex> {item.Qty} x </CartItemIndex>
              <CartItemContent>
                <CartItemContentImg src={item.imageSrc} alt="item1" />
                <CartItemContentText>
                  <CartItemContentTextTitle>
                    {item.Name}
                  </CartItemContentTextTitle>
                  <CartItemContentTextContent>
                    <CartItemContentTextPrice>
                      {(
                        item?.UnitPriceRst *
                        item?.Qty
                      ).toLocaleString()}
                    </CartItemContentTextPrice>
                    <CartItemContentTextPriceBadge src={RstLogo} alt="item" />
                  </CartItemContentTextContent>
                </CartItemContentText>
              </CartItemContent>
              <CartHeaderCloseButton
                style={{ position: "absolute", right: "2rem" }}
                onClick={() => {
                  removeItemFromCart(item.id)
                }}
                src={CloseImg}
                alt="close_btn"
              />
            </CartItem>
            {_index !== cart?.length - 1 && <CartItemDivider />}
          </>
        ))}
      </CartBody>
      <CartFooter>
        <CartFooterSummary>
          <TotalAmountSection>
            <TotalAmountText> Total Price </TotalAmountText>
            <TotalAmountValueSection>
              <TotalAmountValueText>
                {/* formatted balance */}
                {cart.reduce(
                  (accumulator, item) =>
                    accumulator +
                    item?.UnitPriceRst *
                    item?.Qty,
                  0
                ).toLocaleString()}
              </TotalAmountValueText>
              <TotalAmountValueImg src={RstLogo} alt="price_img" />
            </TotalAmountValueSection>
          </TotalAmountSection>
          <CartItemDivider />
        </CartFooterSummary>
        <CompletePurchaseButtonSection>
          <CompletePurchaseButtonArea>
            <CompletePurchaseButton
              onClick={() => {
                handlePurchase(cart, address, setDynamicArgs, setDynamicArgsApprove, approveWriteAsync);
              }}
              disabled={isPurchasing || cart.length === 0}
              className={isLoading || isPurchasing ? "disabled" : ""}>
              Complete Purchase
            </CompletePurchaseButton>
          </CompletePurchaseButtonArea>
        </CompletePurchaseButtonSection>
      </CartFooter>
    </CartContainer>
  );
}
