import styled from 'styled-components';

export const HomePageLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;
  background-color: #0c0416;
`;
