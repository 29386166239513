import React from "react";

import unicornAxe from "../../images/axeunicorn.png";
import rainiLogo from "../../images/icons/logo.png";
import { FooterContent } from "../../content";
import FooterNewsLetter from "../FooterNewsLetter/FooterNewsLetter";
import {
  FooterBackgroundImg,
  FooterContainer,
  FooterContainerHeadline,
  FooterContainerLogoHeadline,
  FooterContainerTop,
  FooterContainerTopCol,
  FooterContainerTopLinksHeadline,
  FooterContainerTopLinksWrap,
  FooterContainerWrapper,
  FooterLinkColumnsContainer,
  FooterNewsletterContainer,
  FooterTopLinksItem,
  FooterBottomRow,
  FooterBottomText,
  FooterBottomLink,
  FooterBottomLinksContainer,
  FooterSocialsContainer,
  FooterSocialLink,
  SocialIcon,
} from "./Footer.style";

import telegramIcon from "../../images/socials/telegram.png";
import twitterIcon from "../../images/socials/twitter.png";
import discordIcon from "../../images/socials/discord.png";
import coinmarketcapIcon from "../../images/socials/coinmarketcap.png";

export default function Footer() {
  const { columns } = FooterContent;

  return (
    <FooterContainerWrapper>
      <FooterContainer>
        <FooterBackgroundImg src={unicornAxe} alt="Chad Raini" />
        <FooterContainerTop>
          <FooterContainerHeadline>
            <FooterContainerLogoHeadline src={rainiLogo} alt="Raini Logo" />
          </FooterContainerHeadline>
          <FooterLinkColumnsContainer>
            {columns.map((column) => (
              <FooterContainerTopCol key={column.id}>
                <FooterContainerTopLinksHeadline>
                  {column.columnName}
                </FooterContainerTopLinksHeadline>
                <FooterContainerTopLinksWrap>
                  {column.links.map((item) => (
                    <FooterTopLinksItem key={item.id} href={item.url}>
                      {item.text}
                    </FooterTopLinksItem>
                  ))}
                </FooterContainerTopLinksWrap>
              </FooterContainerTopCol>
            ))}
          </FooterLinkColumnsContainer>
          <FooterNewsletterContainer>
            <FooterNewsLetter />
          </FooterNewsletterContainer>
        </FooterContainerTop>
        <FooterBottomRow>
          <FooterBottomText>
            © 2024 Raini Studios
          </FooterBottomText>
          <FooterBottomLinksContainer>
            <FooterBottomLink href="https://www.raini.io/privacy" target="_blank">
              Privacy Policy
            </FooterBottomLink>
            <FooterBottomLink href="https://www.raini.io/terms-of-service" target="_blank">
              Terms of Service
            </FooterBottomLink>
          </FooterBottomLinksContainer>
          <FooterSocialsContainer>
            <FooterSocialLink href="https://t.me/rainicornchat" target="_blank">
              <SocialIcon src={telegramIcon} alt="Telegram" />
            </FooterSocialLink>
            <FooterSocialLink href="https://twitter.com/raini_studios" target="_blank">
              <SocialIcon src={twitterIcon} alt="Twitter" />
            </FooterSocialLink>
            <FooterSocialLink href="http://discord.gg/rainiverse" target="_blank">
              <SocialIcon src={discordIcon} alt="Discord" />
            </FooterSocialLink>
            <FooterSocialLink href="https://coinmarketcap.com/currencies/raini-studios-token/" target="_blank">
              <SocialIcon src={coinmarketcapIcon} alt="CoinMarketCap" />
            </FooterSocialLink>
          </FooterSocialsContainer>
        </FooterBottomRow>
      </FooterContainer>
    </FooterContainerWrapper>
  );
}
