import styled from "styled-components";
import welcomeBg from "../../images/bg/welcomeBackground.png";

export const WelcomeContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 500px;
  position: relative;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    height: 475px;
  }
  @media (max-width: 768px) {
    height: 450px;
  }
`;

export const WelcomeBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("${welcomeBg}"), lightgray 50% / cover no-repeat;
  background-size: cover;
  z-index: 30;
`;

export const FlexibleDiv = styled.div`
  position: absolute;
  ${(props) => (props.top ? `top: ${props.top}px;` : "")}
  ${(props) => (props.left ? `left: ${props.left}px;` : "")}
  ${(props) => (props.right ? `right: ${props.right}px;` : "")}
  ${(props) => (props.bottom ? `bottom: ${props.bottom}px;` : "")}
  z-index: 40;
`;

export const FlexibleImg = styled.img`
  ${(props) => (props.width ? `width: ${props.width}px;` : "")}
  ${(props) => (props.height ? `height: ${props.height}px;` : "")}

  @media (max-width: 1200px) {
    ${(props) => (props.width ? `width: ${props.width * 0.85}px;` : "")}
    ${(props) => (props.height ? `height: ${props.height * 0.85}px;` : "")}
  }

  @media (max-width: 800px) {
    ${(props) => (props.width ? `width: ${props.width * 0.7}px;` : "")}
    ${(props) => (props.height ? `height: ${props.height * 0.7}px;` : "")}
  }
`;

export const WelcomeText = styled.span`
  color: #f4e7ff;
  text-align: center;
  font-family: Caudex;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  max-width: 277px;

  display: relative;
  z-index: 999;

  &.rainiverse {
    font-size: 38px;
    font-weight: 400;
    line-height: 38px;
  }
`;
