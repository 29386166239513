import styled from "styled-components";

export const HeaderBackground = styled.div`
  background: rgba(29, 10, 53, 1);
  position: fixed;
  width: 100%;
  z-index: 99999;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LogoDiv = styled.img`
  width: 46px;
  height: 34px;
  margin-left: 23px;
`;

export const InfoDiv = styled.div`
  display: flex;
  gap: 36px;
`;

export const WalletInfoDiv = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
  padding: 23px 0px;
`;

export const WalletInfo = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const AvatarEth = styled.img`
  width: 42px;
  height: 42px;
`;

export const PlayerDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
`;

export const EpicDisplayName = styled.span`
  font-family: "Caudex";
  font-size: 16px;
  color: white;
`;

export const WalletTokenHoldCounts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const TokenIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const TokenBalance = styled.span`
  font-family: "Avenir";
  font-weight: bold;
  font-size: 12px;
  color: white;
`;

export const VerticalLine = styled.div`
  width: 0px;
  height: ${(props) => (props.height ? props.height : 0)}px;
  border: 1px solid rgba(255, 255, 255, 0.16);
`;

export const LogoutImg = styled.img`
  width: 28px;
  height: 28px;
`;

export const CartDiv = styled.div`
  width: 126px;
  background-color: rgba(255, 255, 255, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CartImg = styled.img`
  width: 28px;
  height: 28px;
`;
